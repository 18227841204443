import React, { Component } from 'react'
import AnimalInfo from './AnimalInfo'
import Header from './Header'
import Footer from './Footer'
import Game from './game/Game'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

class App extends Component {
    constructor() {
        super();
        this.state = {
            titleText: "animle"
        }
        this.changeTitle = this.changeTitle.bind(this)
    }

    changeTitle(value) {
        let toText = value
        this.setState({titleText: toText})
    }

    render() {
        return (
                <Box id="appBox"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100%',
                        maxWidth: 'max-content',
                        margin: '0 auto',
                        p: 0,
                        bgcolor: 'background.paper',
                        borderRadius: 1,
                    }}
                >
                    <Header titleText={this.state.titleText} />
                    <Game changeTitle={this.changeTitle} />
                    <Footer />
                </Box>            
        )
    }
}

export default App