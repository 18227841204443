import React from 'react'
import ReactDOM from 'react-dom'
import App from '../components/App'
import "@fontsource/montserrat"

document.addEventListener('DOMContentLoaded', () => {
  let g = document.createElement('div')
  g.id = 'appContainer'
  ReactDOM.render(
    <App />,
    document.body.appendChild(g),
  )
})