import React from 'react'
import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { ThemeProvider } from '@emotion/react';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Box from '@mui/material/Box'
import Link from '@mui/material/Link';

let theme = createTheme()
theme = responsiveFontSizes(theme)
export class Header extends React.Component {
    render() {
        return (
            <Box id="footer"
                sx={{
                    display: 'flex',
                    alignItems: 'end',
                    p: 1,
                    m: 0,
                    bgcolor: 'background.paper',
                    borderRadius: 0,
                }}
            >
                <ThemeProvider theme={theme}>
                    <Link color="#000000" href="https://www.linkedin.com/in/andy-hadjigeorgiou/" target="_blank" >
                    <LinkedInIcon />
                </Link>
                <Link color="#000000" href="http://twitter.com/andyxhadji" target="_blank" >
                    <TwitterIcon />
                </Link>
                </ThemeProvider>
            </Box>
        )
    }

}
export default Header;