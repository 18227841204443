import React from 'react'
import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { ThemeProvider } from '@emotion/react';

let theme = createTheme()
theme = responsiveFontSizes(theme)
export class Header extends React.Component {
    render() {
        return (
            <div>
                <ThemeProvider theme={theme}>
                <Typography id="headerLogo" variant="h3" component="div">
                    {this.props.titleText}
                </Typography>
                <Typography id="headerSubtitle" variant="subtitle1" component="div" align="center">
                    a · nuh · muhl
                </Typography>
                </ThemeProvider>
            </div>
        )
    }

}
export default Header;