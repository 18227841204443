import React from 'react'
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Card from '@mui/material/Card';
import classNames from "classnames";
import HintBox from "./HintBox";

export class GuessBox extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.disabled && !this.props.disabled) {
            this.guessInput.focus()
        }
    }
    componentWillUnmount() {
    }
 
    render() {
        return (
            <div className={classNames({
              animate__animated: this.props.toggleError,
              animate__headShake: this.props.toggleError,
              animate__faster: this.props.toggleError
            })}>
            <Card sx={{margin: 1, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <Box sx={{ alignSelf: 'center'}}>
            <form onSubmit={(e) => this.props.submitGuess(e)}>
                <Input
                  sx={{':after': { borderBottomColor: '#A0522D' },
                  }}
                  value={this.props.guessValue}
                  onChange={this.props.handleGuessChange}
                  placeholder={this.props.placeholder}
                  margin="dense"
                  inputProps={{ 
                    style: {textAlign: 'center'}
                  }}
                  inputRef={el => this.guessInput = el}
                  disabled={this.props.disabled}
                  />
            </form>
            </Box >
            <HintBox
              hints={this.props.hints}
              showHint={this.props.showHint}
            />
            </Card>
            </div>
        )
    }

}
export default GuessBox;