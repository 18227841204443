import React from 'react'
import Chip from '@mui/material/Chip';
import classNames from "classnames";
import 'animate.css';

export class Hint extends React.Component {
    render() {
        let hintStyle = { visibility: this.props.showHint ? 'visible' : 'hidden' }
        let animateDelay = this.props.delay ? ('animate__delay-' + this.props.delay) : 'animate__delay'
        return (
                <div style={hintStyle} className={classNames({
                        animate__animated: this.props.showHint,
                        animate__zoomIn: this.props.showHint,
                        [animateDelay]: this.props.showHint
                    })}>
                        <Chip 
                          label={this.props.label} 
                          color={this.props.color ? this.props.color : 'default'} 
                          size="small"
                        />
                </div>
        )
    }

}
export default Hint;