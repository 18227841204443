import React from 'react'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

export class GameKeyboard extends React.Component {

    constructor() {
        super();
        this.onKeyPress = this.onKeyPress.bind(this)
    }

    componentDidMount() {
    }
    onKeyPress(key) {
        let event = {
            target: {
                value: ""
            }
        }
        switch (key) {
            case '{ent}':
                const submitEvent = {
                    target: [{ value: this.props.guessValue}]
                }
                this.props.submitGuess(submitEvent)
                break;
            case '{backspace}':
                event.target.value = this.props.guessValue.substring(0, this.props.guessValue.length - 1)
                this.props.handleGuessChange(event)
                break;
            default:
                if (key == "{space}") {
                    key = " "
                }
                let guess = this.props.guessValue + key
                event.target.value = guess
                this.props.handleGuessChange(event)
                break;
        }

    }

    keyboardOptions = {
        physicalKeyboardHighlight: true,
        layout: {
            default: [
                "q w e r t y u i o p",
                "a s d f g h j k l",
                "z x c v b n m {backspace}",
                "{space} {ent}"
            ]
        },
        display: {
            "{space}": "space",
            "{ent}": "enter",
            "{backspace}": "⌫"
        },
        buttonTheme: [
                {
                    class: "buttonTheme",
                    buttons: "q w e r t y u i o p a s d f g h j k l z x c v b n m {backspace} {space} {ent}"
                }
        ]
    }

    render() {
        let keyboardWidth = Math.min(window.innerWidth, '350')
        return (
            <div style={{width: keyboardWidth}} id="gameKeyboard">
            <Keyboard
                onKeyReleased={this.onKeyPress}
                {...this.keyboardOptions}
                
            />
            </div>
        )
    }

}
export default GameKeyboard;