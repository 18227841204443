import React from 'react'

export class AnimalInfo extends React.Component {

    constructor() {
        super();
        this.state = {
            animals: []
        };
    }

    componentDidMount() {
        fetch("/api/v1/animals")
            .then(resp => resp.json())
            .then(a => {
                this.setState({
                    animals: a
                })
            })
            .catch(error => console.log(error))
    }

    render() {
        return (
            <div>
                {this.state.animals.map(obj =>
                    <p key={obj.id}>{obj.name}</p>

                )}
            </div>
        )
    }

}
export default AnimalInfo;