import React from 'react'
import Typography from '@mui/material/Typography';
import GuessBox from './GuessBox'
import Api from './Api'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { ThemeProvider } from '@emotion/react';
import GameKeyboard from './GameKeyboard'
import GameDialog from './GameDialog'

let theme = createTheme()
theme = responsiveFontSizes(theme)

let alreadyGuessed = new Set()
let api = new Api()
const LAST_GUESS = 4
const SECOND_LAST_GUESS = LAST_GUESS - 1
const THIRD_LAST_GUESS = SECOND_LAST_GUESS - 1
const MAX_GUESS_LENGTH = 30
export class Game extends React.Component {

    constructor() {
        super();
        this.onlyLetters = this.onlyLetters.bind(this)
        this.isShortEnough = this.isShortEnough.bind(this)
        this.allMatches = this.allMatches.bind(this)
        let guesses = [{},{},{},{},{}].map(() => {
            const guess = {}
            guess['guessValue'] = "",
            guess['placeholder'] = "",
            guess['toggleError'] = false,
            guess['showFirstLetter'] = false,
            guess['showHint'] = false,
            guess['hints'] = {
                'size': {
                    'value': "",
                    'match': false
                },
                'habitat': {
                    'value': "",
                    'match': false
                },
                'class': {
                    'value': "",
                    'match': false
                }
            }
            return guess
        })
        guesses[0]['placeholder'] = "type an animal.."
        this.state = {
            guessNumber: null,
            guesses: guesses,
            firstLetter: '',
            sizeMatch: false,
            habitatMatch: false,
            classMatch: false,
            animalId: null,
            success: null,
            answer: ""
        };
    }
    onlyLetters(str) { return /^[A-Za-z\s]*$/.test(str); }
    isShortEnough(str) { return str.length < MAX_GUESS_LENGTH }
    handleGuessChange(event) {
        // perform all word validation here
        const guesses = this.state.guesses
        const newGuess = event.target.value
        if (this.isShortEnough(newGuess) &&
            this.onlyLetters(newGuess)) 
        {
            guesses[this.state.guessNumber].guessValue = event.target.value
            this.setState({
                guesses: guesses
            })
        }
    }
    allMatches(hints) {
        return (hints['size']['match'] && hints['habitat']['match'] && hints['class']['match']) || this.state.allMatches
    }
    submitGuess(e) {
        e.preventDefault?.()
        const guess = e.target[0].value
        // Submit to API
        api.checkGuess(guess, this.state.animalId)
          .then(checkGuessResponse => {
              const correct = checkGuessResponse['correct']
              const present = checkGuessResponse['present']
              const hints = checkGuessResponse['hints']
              const animalId = checkGuessResponse['animalId']
              const guesses = this.state.guesses

              if (!present || (alreadyGuessed.has(guess) || guess.length == 0)) {
                  guesses[this.state.guessNumber].toggleError = true
                  if (guess.toLowerCase() == "suma" ) {
                      this.props.changeTitle("❤️")
                  }
                  this.setState({ guesses: guesses, animalId: animalId })
              } else {
                  // if checkGuess is true, then the answer is correct!
                  // otherwise, go to next guess
                  if (correct) {
                      guesses[this.state.guessNumber].hints = hints
                      guesses[this.state.guessNumber].showHint = true
                      const answer = guesses[this.state.guessNumber].guessValue
                      this.setState({ guesses: guesses, success: true, animalId: animalId, answer: answer })
                  } else {
                      // if last guess, show hints, but show failure as well (after hints show)
                      guesses[this.state.guessNumber].hints = hints
                      guesses[this.state.guessNumber].showHint = true
                      // If last guess, game over
                      if (this.state.guessNumber == LAST_GUESS) {
                          this.setState({ 
                              guesses: guesses, 
                              success: false,
                              animalId: animalId 
                          })
                      } else {
                          guesses[this.state.guessNumber + 1].placeholder = "begin typing.."
                          const sizeMatch = this.state.sizeMatch || hints['size']['match']
                          const habitatMatch = this.state.habitatMatch || hints['habitat']['match']
                          const classMatch = this.state.classMatch || hints['class']['match']
                          const allMatches = sizeMatch && habitatMatch && classMatch
                          if (this.state.guessNumber == THIRD_LAST_GUESS || allMatches) {
                            guesses[this.state.guessNumber + 1].placeholder = "Starts with letter " + hints['firstLetter'][0] + ".."
                          }
                          if (this.state.guessNumber == SECOND_LAST_GUESS) {
                              guesses[this.state.guessNumber + 1].placeholder = "Starts with letters " + hints['firstLetter'][0] + hints['firstLetter'][1].toLowerCase() + ".."
                          }
                          this.setState({ 
                              firstLetter: hints['firstLetter'], 
                              guesses: guesses, 
                              guessNumber: this.state.guessNumber + 1,
                              animalId: animalId,
                              sizeMatch: sizeMatch,
                              habitatMatch: habitatMatch,
                              classMatch: classMatch
                          })
                      }
                  }
              }
              alreadyGuessed.add(guess)
          })
    }
    componentDidMount() {
        this.setState({
            guessNumber: 0
        })
    }
    componentDidUpdate(prevProps, prevState) {
        const guesses = this.state.guesses
        const guess = guesses[this.state.guessNumber]
        if (guess.toggleError) {
            setTimeout(
                () => {
                    const guess = guesses[this.state.guessNumber]
                    guesses[this.state.guessNumber].toggleError = false
                    this.setState({ guesses: guesses })
                },
                250
            )
        }
    }
    onChange(input) {

    }
    onKeyPress(button) {

    }
    render() {
        let gameWidth = Math.min(window.innerWidth, '350')
        const styles = {
            container: {
                width: gameWidth
            }
        }
        let dialogOpen = false
        if (this.state.success !== null) {
            dialogOpen = true
        }
        return (
            <ThemeProvider theme={theme}>
            <GameDialog 
                open={dialogOpen}
                success={this.state.success}
                answer={this.state.answer}
                guesses={this.state.guesses}

            />
            <div style={styles.container}>
                <GuessBox 
                  {...this.state.guesses[0]}
                  firstLetter={this.state.firstLetter}
                  handleGuessChange={this.handleGuessChange.bind(this)}
                  disabled={this.state.guessNumber == 0 ? false : true}
                  submitGuess={this.submitGuess.bind(this)}
                />
                <GuessBox
                    {...this.state.guesses[1]}
                    firstLetter={this.state.firstLetter}
                    handleGuessChange={this.handleGuessChange.bind(this)}
                    disabled={this.state.guessNumber == 1 ? false : true}
                    submitGuess={this.submitGuess.bind(this)}
                />
                <GuessBox
                    {...this.state.guesses[2]}
                    firstLetter={this.state.firstLetter}
                    handleGuessChange={this.handleGuessChange.bind(this)}
                    disabled={this.state.guessNumber == 2 ? false : true}
                    submitGuess={this.submitGuess.bind(this)}
                />
                <GuessBox
                    {...this.state.guesses[3]}
                    firstLetter={this.state.firstLetter}
                    handleGuessChange={this.handleGuessChange.bind(this)}
                    disabled={this.state.guessNumber == 3 ? false : true}
                    submitGuess={this.submitGuess.bind(this)}
                />
                <GuessBox
                    {...this.state.guesses[4]}
                    firstLetter={this.state.firstLetter}
                    handleGuessChange={this.handleGuessChange.bind(this)}
                    disabled={this.state.guessNumber == 4 ? false : true}
                    submitGuess={this.submitGuess.bind(this)}
                />
            </div>
            <GameKeyboard
                {...this.state.guesses[this.state.guessNumber]}
                handleGuessChange={this.handleGuessChange.bind(this)}
                submitGuess={this.submitGuess.bind(this)}
            />
            </ThemeProvider>
        )
    }
}
export default Game;