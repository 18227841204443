import axios from 'axios';

class Api {
    constructor() {

    } 
    checkGuess(guess, animalId) {
        let guessParam = guess.toLowerCase()
        let toReturn = {}
        const guessPromise = axios.post(`/api/v1/animals/search`, { guessParam, animalId })
            .then(res => {
                const respJson = res.data
                const hintsJson = respJson['hints']
                const checkGuessResp = {
                    correct: respJson['correct'],
                    present: respJson['present'],
                    hints: hintsJson,
                    animalId: respJson['animalId']
                }
                return checkGuessResp
            })
        return guessPromise
    }
}

export default Api