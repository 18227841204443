import React from 'react'
import classNames from "classnames";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

export class GameDialog extends React.Component {
    
    constructor() {
        super();
        this.state = {
            anchorEl: null
        }
        this.refreshPage = this.refreshPage.bind(this)
        this.setAnchorEl = this.setAnchorEl.bind(this)
        this.onShareClick = this.onShareClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.textToCopy = this.textToCopy.bind(this)
    }

    setAnchorEl(target) {
        this.setState({anchorEl: target})
    }
    refreshPage() {
        window.location.reload(false);
    }
    handleClose = () => {
        this.setAnchorEl(null);
    }
    getDate() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
        var yyyy = today.getFullYear()
        today = mm + "/" + dd + "/" + yyyy;
        return today
    }
    onShareClick(event) {
        this.setAnchorEl(event.currentTarget);
        const attempts = localStorage.getItem(this.getDate())
        let text = this.textToCopy(attempts)
        navigator.clipboard.writeText(text)
    }
    textToCopy(attempts) {
        let text = "animle " + this.getDate() + "\n"
        let tryCopy = ""
        if (attempts == 1) {
            tryCopy = " try"
        } else {
            tryCopy = " tries"
        }
        text = text + attempts + tryCopy + "\n"
        let hints = ['size', 'habitat', 'class']
        for (const guess of this.props.guesses) {
            if (guess['guessValue']) {
                for (const hint of hints) {
                    if (guess['guessValue']) {
                        if (guess['hints'][hint]['match']) {
                            text += "🟢"
                        } else {
                            text += "⚪"
                        }
                   }
                }
                text += "\n"
            }
        }
        return text
    }
    render() {
        const open = Boolean(this.state.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        let newAttempts = 0
        if (this.props.success !== null) {
            let today = this.getDate()
            const attempts = JSON.parse(localStorage.getItem(today))
            
            if (attempts == null) {
                newAttempts = 1
            } else {
                newAttempts = attempts + 1
            }
            localStorage.setItem(today, newAttempts)
        }
        let dialogWidth = Math.min(window.innerWidth, '300')
        let egg = ""
        if (this.props.answer.toLowerCase() == "lobster") {
            egg = "Excellent choice."
        }
        return (
                <div>
                    <Dialog
                      id="gameDialog" 
                      open={this.props.open}
                      TransitionProps={{
                        style: {
                            transitionDelay: 3500,
                        }
                      }}
                      fullWidth={true}
                      maxWidth={'sm'}
                    >
                    <DialogTitle textAlign="center">
                     { this.props.success &&
                     <div>
                        <span id="emoji" role="img" aria-label="tada">🎉</span>
                            Congratulations!
                        <span id="emoji" role="img" aria-label="tada">🎉</span>
                        </div>
                     }
                     { !this.props.success && 
                        <div>
                            Sorry. <span id="emoji" role="img" aria-label="sob">😭</span>
                        </div>
                     }
                        </DialogTitle>
                    <DialogContent>
                        { this.props.success && 
                            <DialogContentText textAlign="center">
                                Today's animle is {this.props.answer}. {egg}
                            </DialogContentText>
                        }
                        { !this.props.success &&
                            <DialogContentText textAlign="center">
                                Today's animle remains a mystery.
                            </DialogContentText>
                        }
                    </DialogContent>
                    <DialogActions>
                        { this.props.success &&
                            <Button aria-describedby={id} onClick={this.onShareClick}>Share</Button>
                        }
                        { !this.props.success &&
                            <Button onClick={this.refreshPage}>Try Again</Button>
                        }
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Typography sx={{ p: 1 }}>Copied to clipboard.</Typography>
                        </Popover>
                    </DialogActions>
                    </Dialog>
                </div>
        )
    }

}
export default GameDialog;