import React from 'react'
import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import { ThemeProvider } from '@emotion/react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Hint from './Hint'
import classNames from "classnames";
import 'animate.css';


let theme = createTheme()
theme = responsiveFontSizes(theme)
export class HintBox extends React.Component {
    render() {
        let hintStyle = { visibility: this.props.showHint ? 'visible' : 'hidden' }
        return (
                <ThemeProvider theme={theme}>
                    <Box sx={{ margin: '4px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Hint
                      showHint={this.props.showHint}
                      delay={""}
                      label={this.props.hints['size']['value']}
                      color={this.props.hints['size']['match'] ? "success" : ""}
                    />
                    <Hint
                        showHint={this.props.showHint}
                        delay={"1s"}
                        label={this.props.hints['habitat']['value']}
                        color={this.props.hints['habitat']['match'] ? "success" : ""}
                    />
                    <Hint
                        showHint={this.props.showHint}
                        delay={"2s"}
                        label={this.props.hints['class']['value']}
                        color={this.props.hints['class']['match'] ? "success" : ""}
                    />
                    </Box>
                </ThemeProvider>
        )
    }

}
export default HintBox;